import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-harvesting-icon',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="flex items-center justify-center">
      <i [class]="iconClass" [ngClass]="{ 'text-green-500': !hasError, 'text-red-500': hasError }"></i>
    </div>
  `,
  styleUrl: './error-harvesting-icon.component.scss',
})
export class ErrorHarvestingIconComponent {
  @Input({ required: true }) hasError: boolean = false;

  get iconClass(): string {
    return this.hasError ? 'pi pi-times-circle' : 'pi pi-check-circle';
  }
}
