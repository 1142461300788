import { ILoginLinkedinResponse, ILoginLinkedinResponseDTO } from '../interfaces/login-linkedin.interface';
import { ILoginResponse, ILoginResponseDTO } from '../interfaces/login.interface';

export class AuthMapper {
  static fromDTO(dto: ILoginResponseDTO): ILoginResponse {
    return {
      accessToken: dto.token,
      refreshToken: dto.refreshToken,
      isFirstLoggedIn: dto.isFirstLoggedIn,
    };
  }

  static mapLoginLinkedinDTOtoModel(dto: ILoginLinkedinResponseDTO): ILoginLinkedinResponse {
    return {
      accessToken: dto.token,
      refreshToken: dto.refreshToken,
    };
  }
}
