import { Injectable } from '@angular/core';
import { IPaginatedPayload } from '../../../../@core/interfaces/paginated.interface';
import { ListController } from '../api/list.controller';
import { ListMapper } from '../api/list.mapper';
import { IListDetailPaginated, IListDetailResponseDTO } from '../interfaces/list-detail.interface';
import { IListPaginated, IListResponseDTO } from '../interfaces/list.interface';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  constructor(private listController: ListController) {}

  getLists(payload: IPaginatedPayload): Promise<IListPaginated> {
    return new Promise((resolve) => {
      this.listController.getLists(payload).subscribe({
        next: (response: IListResponseDTO) => {
          const lists: IListPaginated = ListMapper.mapPaginatedListDtoToModel(response);
          resolve(lists);
        },
        error: () => {
          resolve(null);
        },
      });
    });
  }

  getListById(id: string, payload: IPaginatedPayload): Promise<IListDetailPaginated> {
    return new Promise((resolve) => {
      this.listController.getListById(id, payload).subscribe({
        next: (response: IListDetailResponseDTO) => {
          const listDetailPaginated: IListDetailPaginated = ListMapper.mapPaginatedListDetailDtoToModel(response);
          resolve(listDetailPaginated);
        },
        error: () => {
          resolve(null);
        },
      });
    });
  }
}
