import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToasterHelper } from '../helpers/toaster.helper';
import { IErrorToSkip } from '../interfaces/error-skip.interface';

const errorsToSkip: IErrorToSkip[] = [{ status: 401 }, { status: 400, message: 'Invalid token.' }];

export const errorsInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const messageService = inject(MessageService);

  return next(req).pipe(
    tap({
      error: (error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const shouldSkip = errorsToSkip.some(
            (errorToSkip) =>
              error.status === errorToSkip.status &&
              (!errorToSkip.message || (Array.isArray(error.error) && error.error[0] === errorToSkip.message)),
          );

          if (shouldSkip) {
            return;
          }

          const errorMessage: string = Array.isArray(error.error)
            ? error.error[0]
            : (error.error as { message: string })?.message || 'Something went wrong. Please try again later.';
          messageService.add(ToasterHelper.errorMessage(errorMessage));
        }
      },
    }),
  );
};
