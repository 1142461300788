import { IEducationDto, IExperienceDto, ILead, ILeadDto, IPostDto, ISkillDto } from '../interfaces/lead.interface';
import { ILeadSummary, ILeadSummaryDTO, ILeadSummaryPaginated, ILeadSummaryResponseDTO } from '../interfaces/leads-summary.interface';

export class LeadMapper {
  static mapLeadsSummaryDtoToModel(dto: ILeadSummaryDTO): ILeadSummary {
    return {
      leadSummary: {
        leadId: dto.leadSummary.leadId,
        firstName: dto.leadSummary.firstName,
        lastName: dto.leadSummary.lastName,
        jobTitle: dto.leadSummary.jobTitle,
        location: dto.leadSummary.location,
        summary: dto.leadSummary.summary,
        insight: dto.leadSummary.insight,
        url: dto.leadSummary.url,
        picture: dto.leadSummary.picture,
        lastHarvest: dto.leadSummary.lastHarvest,
        errorHarvesting: dto.leadSummary.errorHarvesting,
      },
      searchAppearances: dto.searchAppearances,
    };
  }

  static mapPaginatedLeadsSummaryDtoToModel(dto: ILeadSummaryResponseDTO): ILeadSummaryPaginated {
    return {
      size: dto.size,
      page: dto.page,
      orderBy: dto.orderBy,
      desc: dto.desc,
      totalCount: dto.totalCount,
      data: dto.data.map((item) => LeadMapper.mapLeadsSummaryDtoToModel(item)),
    };
  }

  static mapLeadDtoToModel(dto: ILeadDto): ILead {
    return {
      id: dto.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      headline: dto.headline,
      position: dto.position,
      about: dto.about,
      educations: dto.educations.map((education: IEducationDto) => ({
        school: education.school,
        qualification: education.qualification,
        startDate: education.startDate,
        endDate: education.endDate,
        description: education.description,
      })),
      posts: dto.posts.map((post: IPostDto) => ({
        content: post.content,
        postedBy: post.postedBy,
        reactionsCount: post.reactionsCount,
        repostsCount: post.repostsCount,
      })),
      experiences: dto.experiences.map((experience: IExperienceDto) => ({
        title: experience.title,
        employmentType: experience.employmentType,
        companyName: experience.companyName,
        location: experience.location,
        locationType: experience.locationType,
        startDate: experience.startDate,
        endDate: experience.endDate,
      })),
      skills: dto.skills.map((skill: ISkillDto) => ({
        title: skill.title,
        endorsementsCount: skill.endorsementsCount,
        isTopSkill: skill.isTopSkill,
        experienceWithSkillCount: skill.experienceWithSkillCount,
      })),
    };
  }
}
