import { Injectable } from '@angular/core';
import { IPaginatedPayload } from '../../../../@core/interfaces/paginated.interface';
import { LeadController } from '../api/lead.controller';
import { LeadMapper } from '../api/lead.mapper';
import { ILead, ILeadDto } from '../interfaces/lead.interface';
import { ILeadSummaryPaginated, ILeadSummaryResponseDTO } from '../interfaces/leads-summary.interface';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  constructor(private leadController: LeadController) {}

  getLeadById(payload: string): Promise<ILead> {
    return new Promise<ILead>((resolve) => {
      this.leadController.getLeadById(payload).subscribe({
        next: (res: ILeadDto) => {
          const response = LeadMapper.mapLeadDtoToModel(res);
          resolve(response);
        },
        error: (error) => {
          console.error('Get Lead error:', error);
          resolve(null);
        },
      });
    });
  }

  getLeads(payload: IPaginatedPayload): Promise<ILeadSummaryPaginated> {
    return new Promise<ILeadSummaryPaginated>((resolve) => {
      this.leadController.getLeads(payload).subscribe({
        next: (res: ILeadSummaryResponseDTO) => {
          const response: ILeadSummaryPaginated = LeadMapper.mapPaginatedLeadsSummaryDtoToModel(res);
          resolve(response);
        },
        error: (error) => {
          console.error('Get Leads error:', error);
          resolve(null);
        },
      });
    });
  }
}
