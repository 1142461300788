import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IPaginatedPayload } from '../../../../@core/interfaces/paginated.interface';
import { appRoutes } from '../../../../shared/constants/routes.constants';
import { IListPaginated } from '../../@core/interfaces/list.interface';
import { ListService } from '../../@core/services/list.service';
@Component({
  selector: 'app-lists',
  standalone: true,
  imports: [TableModule, CardModule, RouterLink, CommonModule, SkeletonModule, InputTextModule, FormsModule],
  templateUrl: './available-lists.component.html',
  styleUrl: './available-lists.component.scss',
})
export class ListsComponent implements OnInit, OnDestroy {
  lists: IListPaginated = null;

  pageSize: number = 10;
  currentPage: number = 0;
  totalRecords: number = 0;
  searchQuery: string = '';
  lastSearchQuery: string = '';
  sortField: string = '';
  sortOrder: number = 0;

  isLoading: boolean = false;
  readonly route = appRoutes;

  private _searchSubject = new Subject<string>();
  private _destroy$ = new Subject<void>();

  constructor(
    private listService: ListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.readQueryParams();
    this.initializeSearch();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private readQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const validPageSizes = [5, 10, 15];

      this.currentPage = +params['page'] || 0;
      const pageSizeUrl = +params['size'] || 10;

      if (validPageSizes.includes(pageSizeUrl)) {
        this.pageSize = pageSizeUrl;
      } else {
        this.pageSize = 10;
      }

      this.sortField = params['orderBy'] || '';
      this.sortOrder = params['desc'] === 'true' ? -1 : 1;

      this.searchQuery = params['search'] || '';
      this.lastSearchQuery = this.searchQuery;
    });
  }

  private initializeSearch(): void {
    this._searchSubject.pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this._destroy$)).subscribe(() => {
      this.currentPage = 0;
      this.loadLists();
    });
  }

  onSearch(event: Event): void {
    const searchValue = (event.target as HTMLInputElement).value;
    this._searchSubject.next(searchValue);
  }

  private updateQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: this.currentPage,
        size: this.pageSize,
        search: this.searchQuery || null,
        desc: this.sortOrder === -1 ? 'true' : 'false',
        orderBy: this.sortField || null,
      },
      queryParamsHandling: 'merge',
    });
  }

  async loadLists(event?: TableLazyLoadEvent): Promise<void> {
    const newPage = event ? Math.floor(event.first / event.rows) : this.currentPage;

    if (
      this.isLoading &&
      newPage === this.currentPage &&
      event?.sortField === this.sortField &&
      event?.sortOrder === this.sortOrder &&
      this.searchQuery === this.lastSearchQuery &&
      this.pageSize === event.rows
    ) {
      return;
    }

    this.isLoading = true;

    if (event) {
      this.currentPage = newPage;
      this.pageSize = event.rows;
      this.sortField = Array.isArray(event.sortField) ? event.sortField[0] : event.sortField || '';
      this.sortOrder = event.sortOrder || 0;
    }

    this.updateQueryParams();

    const payload: IPaginatedPayload = {
      size: this.pageSize,
      page: this.currentPage,
    };

    if (event?.sortField) {
      payload.orderBy = Array.isArray(event.sortField) ? event.sortField[0] : event.sortField;
    }

    if (event?.sortOrder !== undefined) {
      payload.desc = event.sortOrder === -1;
    }

    if (this.searchQuery) {
      payload.search = this.searchQuery;
    }

    const data = await this.listService.getLists(payload);

    if (!data) {
      this.isLoading = false;
      return;
    }

    this.lists = data;
    this.totalRecords = data.totalCount;
    this.lastSearchQuery = this.searchQuery;
    this.sortField = payload.orderBy;
    this.sortOrder = payload.desc ? -1 : 1;
    this.isLoading = false;
  }
}
