<p-card
  header="{{ name }}"
  class="[&_.p-card]:rounded-xl [&_.p-card]:border [&_.p-card-content]:p-0 [&_.p-card]:border-solid [&_.p-card]:border-gray-200 [&_.p-card]:px-3 [&_.p-card]:py-2 [&_.p-card-title]:text-lg [&_.p-card-title]:font-bold [&_.p-card-title]:text-gray-700 [&_.p-card-title]:px-3.5 [&_.p-card-title]:py-3 [&_.p-card-body]:p-0"
>
  <p-table
    [value]="listDetailPaginated?.data ?? [1, 2, 3, 4, 5, 6, 7]"
    [rows]="pageSize"
    [paginator]="!isLoading"
    [lazy]="true"
    (onLazyLoad)="loadDetailLists($event)"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [rowsPerPageOptions]="[5, 10, 15]"
    [totalRecords]="listDetailPaginated?.totalCount"
    [first]="currentPage * pageSize"
    class="[&_.p-datatable-tbody>tr:last-child>td]:border-b-0 [&_.p-datatable-header]:p-0 [&_.p-dropdown-label]:py-[8px]"
  >
    <ng-template pTemplate="caption">
      <div class="flex items-center justify-between px-2 py-2">
        <span class="p-input-icon-left w-full max-w-xs">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            [(ngModel)]="searchQuery"
            (input)="onSearch($event)"
            [placeholder]="'Search ' + (name | lowercase)"
            class="p-inputtext-sm w-full"
            [disabled]="isLoading"
          />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr class="[&>th]:bg-transparent [&>th]:py-2.5 [&>th]:px-3.5 [&>th]:text-sm [&>th]:text-gray-700">
        <th pSortableColumn="errorHarvesting" [pSortableColumnDisabled]="isLoading">
          Error harvesting <p-sortIcon field="errorHarvesting"></p-sortIcon>
        </th>
        <th pSortableColumn="firstName" [pSortableColumnDisabled]="isLoading">Name<p-sortIcon field="firstName"></p-sortIcon></th>
        <th pSortableColumn="jobTitle" [pSortableColumnDisabled]="isLoading">Job Title<p-sortIcon field="jobTitle"></p-sortIcon></th>
        <th pSortableColumn="location" [pSortableColumnDisabled]="isLoading">Location <p-sortIcon field="location"></p-sortIcon></th>
        <th pSortableColumn="summary" [pSortableColumnDisabled]="isLoading">Summary<p-sortIcon field="summary"></p-sortIcon></th>
        <th pSortableColumn="insight" [pSortableColumnDisabled]="isLoading">Insight<p-sortIcon field="insight"></p-sortIcon></th>
        <th class="text-center">Linkedin</th>
        <th pSortableColumn="lastHarvest" [pSortableColumnDisabled]="isLoading">
          Last harvest<p-sortIcon field="lastHarvest"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-listDetailItem>
      <tr class="[&>td]:py-2.5 [&>td]:px-3.5 [&>td]:text-sm">
        <td *ngIf="!isLoading; else loading" class="text-center">
          <app-error-harvesting-icon [hasError]="listDetailItem.errorHarvesting"></app-error-harvesting-icon>
        </td>
        <td *ngIf="!isLoading; else loadingCircle" class="flex items-center gap-2">
          <p-avatar
            #avatar
            [image]="listDetailItem.picture"
            (onImageError)="onAvatarError(listDetailItem)"
            [label]="listDetailItem.imageError ? getInitials(listDetailItem.firstName, listDetailItem.lastName) : null"
            shape="circle"
            class="[&_.p-avatar]:size-8 [&_.p-avatar]:flex cursor-pointer"
            [routerLink]="[listDetailItem.firstName + ' ' + listDetailItem.lastName | encodeURI, listDetailItem.leadId]"
          ></p-avatar>
          <a
            [routerLink]="[listDetailItem.firstName + ' ' + listDetailItem.lastName | encodeURI, listDetailItem.leadId]"
            routerLinkActive="router-link-active"
            class="text-brandColor hover:text-blue-700 no-underline font-semibold"
          >
            {{ listDetailItem.firstName }} {{ listDetailItem.lastName }}
          </a>
        </td>
        <td *ngIf="!isLoading; else loading" class="text-left">{{ listDetailItem.jobTitle }}</td>
        <td *ngIf="!isLoading; else loading" class="text-left">{{ listDetailItem.location }}</td>
        <td *ngIf="!isLoading; else loading" class="text-left">{{ listDetailItem.summary }}</td>
        <td *ngIf="!isLoading; else loading" class="text-left">{{ listDetailItem.insight }}</td>
        <td *ngIf="!isLoading; else loading" class="text-center">
          <a [href]="listDetailItem.url"><i class="pi pi-link text-brandColor hover:text-blue-700"></i></a>
        </td>
        <td *ngIf="!isLoading; else loading" class="text-left">{{ listDetailItem.lastHarvest | date }}</td>
        <ng-template #loadingCircle>
          <td class="flex items-center gap-2">
            <p-skeleton [shape]="'circle'" [width]="'32px'" [height]="'32px'" class="mr-2"></p-skeleton>
            <div class="flex-1">
              <p-skeleton [width]="'100%'" [height]="'1rem'" class="mb-1"></p-skeleton>
            </div>
          </td>
        </ng-template>
        <ng-template #loading>
          <td><p-skeleton [width]="'100%'" [height]="'1.5rem'"></p-skeleton></td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
</p-card>
