import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { ILead } from '../../@core/interfaces/lead.interface';
import { LeadService } from '../../@core/services/lead.service';

@Component({
  selector: 'app-lead',
  standalone: true,
  templateUrl: './lead.component.html',
  styleUrl: './lead.component.scss',
  imports: [CardModule, TableModule, CommonModule, SkeletonModule, AvatarModule],
})
export class LeadComponent implements OnInit {
  lead!: ILead;
  leadId: string = null;

  isLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private leadService: LeadService,
  ) {}

  ngOnInit(): void {
    this.initializeLeadId();
    if (this.leadId) {
      this.loadLead();
    }
  }

  private async loadLead(): Promise<void> {
    this.isLoading = true;

    const response: ILead = await this.leadService.getLeadById(this.leadId);

    if (!response) {
      this.isLoading = false;
      return;
    }

    this.lead = response;
    this.isLoading = false;
  }

  private initializeLeadId(): void {
    this.leadId = this.activatedRoute.snapshot.params['leadId'] || null;
  }

  getInitials(firstName: string, lastName: string): string {
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  }
}
