import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../../../@core/services/account.service';
import { TokenService } from '../../../../@core/services/token.service';
import { URLS } from '../../../../shared/enums/urls.enum';
import { AuthController } from '../api/auth.api';
import { AuthMapper } from '../api/auth.mapper';
import { IConfirmEmailPayload } from '../interfaces/confirm-email.interface';
import { IForgotEmailPayload } from '../interfaces/forgot-email.interface';
import { ILoginLinkedinPayload, ILoginLinkedinResponse } from '../interfaces/login-linkedin.interface';
import { ILoginPayload, ILoginResponse, ILoginResponseDTO } from '../interfaces/login.interface';
import { IRegisterPayload } from '../interfaces/register.interface';
import { IResetPasswordPayload } from '../interfaces/reset-password.interface';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private tokenService: TokenService,
    private accountService: AccountService,
    private authController: AuthController,
    private router: Router,
  ) {}

  register(payload: IRegisterPayload): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.authController.register(payload).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error) => {
          console.error('Register error:', error);
          resolve(false);
        },
      });
    });
  }

  login(payload: ILoginPayload): Promise<ILoginResponse> {
    return new Promise<ILoginResponse>((resolve) => {
      this.authController.login(payload).subscribe({
        next: (response: ILoginResponseDTO) => {
          const loginData = AuthMapper.fromDTO(response);
          const { accessToken, refreshToken } = loginData;
          this.tokenService.setCurrentAccessToken(accessToken);
          this.tokenService.setCurrentRefreshToken(refreshToken);

          resolve(loginData);
        },
        error: (error) => {
          console.error('Login error:', error);
          resolve(null);
        },
      });
    });
  }

  loginWithLinkedIn(authorizationCode: ILoginLinkedinPayload): Promise<ILoginLinkedinResponse> {
    return new Promise<ILoginLinkedinResponse>((resolve) => {
      this.authController.loginWithLinkedIn(authorizationCode).subscribe({
        next: (response) => {
          const loginData = AuthMapper.mapLoginLinkedinDTOtoModel(response);
          const { accessToken, refreshToken } = loginData;
          this.tokenService.setCurrentAccessToken(accessToken);
          this.tokenService.setCurrentRefreshToken(refreshToken);

          resolve(loginData);
        },
        error: (error) => {
          console.error('Login with LinkedIn error:', error);
          resolve(null);
        },
      });
    });
  }

  confirmAccount(token: IConfirmEmailPayload): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.authController.confirmEmail(token).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error) => {
          console.error('Confirm account error:', error);
          resolve(false);
        },
      });
    });
  }

  logout(): Promise<boolean> {
    this.accountService.clearUser();
    this.router.navigate([URLS.LOGIN_PATH]);

    const logoutPromise = new Promise<boolean>((resolve) => {
      this.authController.logout().subscribe({
        next: () => {
          resolve(true);
        },
        error: (error) => {
          console.error('Logout error:', error);
          resolve(false);
        },
      });
    });

    this.tokenService.clearTokens();

    return logoutPromise;
  }

  forgotPassword(email: IForgotEmailPayload): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.authController.forgotPassword(email).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error) => {
          console.error('Forgot password error:', error);
          resolve(false);
        },
      });
    });
  }

  changePassword(payload: IResetPasswordPayload): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.authController.changePassword(payload).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error) => {
          console.error('Change password error:', error);
          resolve(false);
        },
      });
    });
  }

  clearAuthData(): void {
    this.tokenService.clearTokens();
    this.accountService.clearUser();
  }

  isAuthenticated(): boolean {
    const token = TokenService.getCurrentAccessToken();
    return !!token;
  }
}
