import { Component } from '@angular/core';
import { LeadComponent } from '../../../leads/components/lead/lead.component';

@Component({
  selector: 'app-search-details',
  standalone: true,
  templateUrl: './search-details.component.html',
  styleUrl: './search-details.component.scss',
  imports: [LeadComponent],
})
export class SearchDetailsComponent {}
