export enum URLS {
  ROOT_PATH = '/',
  LOGIN_PATH = '/auth/login',
  REGISTER_PATH = '/auth/register',
  FORGOT_PASSWORD_PATH = '/auth/forgot-password',
  DASHBOARD_PATH = '/dashboard',
  SEQUENCES_PATH = '/sequences',
  LISTS_PATH = '/lists',
  LEADS_PATH = '/leads',
  PROFILE_PATH = '/profile',
  INBOX_PATH = '/inbox',
  RECENT_ACTIVITY_PATH = '/recent-activity',
  SETTINGS_PATH = '/settings',
}
