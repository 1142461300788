<p-card
  header="Available lists"
  class="[&_.p-card]:rounded-xl [&_.p-card]:border [&_.p-card-content]:p-0 [&_.p-card]:border-solid [&_.p-card]:border-gray-200 [&_.p-card]:px-3 [&_.p-card]:py-2 [&_.p-card-title]:text-lg [&_.p-card-title]:font-bold [&_.p-card-title]:text-gray-700 [&_.p-card-title]:px-3.5 [&_.p-card-title]:py-3 [&_.p-card-body]:p-0"
>
  <p-table
    [value]="lists?.data ?? [1, 2, 3, 4, 5, 6, 7]"
    [rows]="pageSize"
    [paginator]="!isLoading"
    [lazy]="true"
    (onLazyLoad)="loadLists($event)"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [rowsPerPageOptions]="[5, 10, 15]"
    [totalRecords]="lists?.totalCount"
    [first]="currentPage * pageSize"
    class="[&_.p-datatable-tbody>tr:last-child>td]:border-b-0 [&_.p-datatable-header]:p-0 [&_.p-dropdown-label]:py-[8px]"
  >
    <ng-template pTemplate="caption">
      <div class="flex items-center justify-between px-2 py-2">
        <span class="p-input-icon-left w-full max-w-xs">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            [(ngModel)]="searchQuery"
            (input)="onSearch($event)"
            placeholder="Search lists"
            class="p-inputtext-sm w-full"
            [disabled]="isLoading"
          />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr class="[&>th]:bg-transparent [&>th]:py-2.5 [&>th]:px-3.5 [&>th]:text-sm [&>th]:text-gray-700">
        <th pSortableColumn="name" [pSortableColumnDisabled]="isLoading">Name <p-sortIcon field="name"></p-sortIcon></th>
        <th pSortableColumn="description" [pSortableColumnDisabled]="isLoading">
          Description <p-sortIcon field="description"></p-sortIcon>
        </th>
        <th pSortableColumn="searchLeadsTotal" [pSortableColumnDisabled]="isLoading" class="text-right">
          Search Leads Total <p-sortIcon field="searchLeadsTotal"></p-sortIcon>
        </th>
        <th pSortableColumn="searchLeadsHarvested" [pSortableColumnDisabled]="isLoading" class="text-right">
          Search Leads Harvested <p-sortIcon field="searchLeadsHarvested"></p-sortIcon>
        </th>
        <th pSortableColumn="searchLeadsErrors" [pSortableColumnDisabled]="isLoading" class="text-right">
          Search Leads Errors <p-sortIcon field="searchLeadsErrors"></p-sortIcon>
        </th>
        <th pSortableColumn="leadsTotal" [pSortableColumnDisabled]="isLoading" class="text-right">
          Leads Total <p-sortIcon field="leadsTotal"></p-sortIcon>
        </th>
        <th pSortableColumn="leadsActive" [pSortableColumnDisabled]="isLoading" class="text-right">
          Leads Active <p-sortIcon field="leadsActive"></p-sortIcon>
        </th>
        <th pSortableColumn="leadsExpired" [pSortableColumnDisabled]="isLoading" class="text-right">
          Leads Expired <p-sortIcon field="leadsExpired"></p-sortIcon>
        </th>
        <th pSortableColumn="leadsHarvested" [pSortableColumnDisabled]="isLoading" class="text-right">
          Leads Harvested <p-sortIcon field="leadsHarvested"></p-sortIcon>
        </th>
        <th pSortableColumn="leadsErrors" [pSortableColumnDisabled]="isLoading" class="text-right">
          Leads Errors <p-sortIcon field="leadsErrors"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-listItem>
      <tr class="[&>td]:py-2.5 [&>td]:px-3.5 [&>td]:text-sm">
        <td *ngIf="!isLoading; else loading" class="text-left">
          <a
            [routerLink]="[listItem.name, listItem.id]"
            routerLinkActive="router-link-active"
            class="text-brandColor hover:text-blue-700 no-underline font-semibold"
          >
            {{ listItem.name }}
          </a>
        </td>
        <td *ngIf="!isLoading; else loading" class="text-left">{{ listItem.description }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.searchLeadsTotal }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.searchLeadsHarvested }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.searchLeadsErrors }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.leadsTotal }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.leadsActive }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.leadsExpired }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.leadsHarvested }}</td>
        <td *ngIf="!isLoading; else loading" class="text-right">{{ listItem.leadsErrors }}</td>
        <ng-template #loading>
          <td><p-skeleton [width]="'100%'" [height]="'1.5rem'"></p-skeleton></td>
        </ng-template>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="10" class="text-center py-4">
          <div class="flex flex-col items-center gap-4">
            <i class="pi pi-list text-3xl text-gray-400"></i>
            <p class="text-sm text-gray-500 font-medium">No lists available at the moment.</p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
