import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../@core/api/api.service';
import { IPaginatedPayload, IPaginatedResponse } from '../../../../@core/interfaces/paginated.interface';
import { URLS } from '../../../../shared/enums/urls.enum';
import { IListDetailDto } from '../interfaces/list-detail.interface';
import { IListResponseDTO } from '../interfaces/list.interface';

@Injectable({
  providedIn: 'root',
})
export class ListController {
  private readonly _path = URLS.LISTS_PATH;

  constructor(private apiService: ApiService) {}

  getLists(payload: IPaginatedPayload): Observable<IListResponseDTO> {
    return this.apiService.get<IListResponseDTO>(this._path, payload);
  }

  getListById(id: string, payload: IPaginatedPayload): Observable<IPaginatedResponse<IListDetailDto>> {
    return this.apiService.get<IPaginatedResponse<IListDetailDto>>(`${this._path}/${id}`, payload);
  }
}
