import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INewAccessTokenDTO, INewAccessTokenPayload } from '../interfaces/new-access-token.interface';
import { IUserDTO } from '../interfaces/user.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountController {
  private readonly _path = '/account';

  constructor(private apiService: ApiService) {}

  getMe(): Observable<IUserDTO> {
    return this.apiService.get(`${this._path}/me`);
  }

  getNewAccessToken(token: INewAccessTokenPayload): Observable<INewAccessTokenDTO> {
    return this.apiService.post(`${this._path}/refresh-token`, token);
  }
}
