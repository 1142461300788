import { Routes } from '@angular/router';
import { ListsComponent } from '../features/lists/components/available-lists/available-lists.component';
import { SearchDetailsComponent } from '../features/lists/components/search-details/search-details.component';
import { SearchesListComponent } from '../features/lists/components/searches-list/searches-list.component';
import { appRoutes } from '../shared/constants/routes.constants';

export const applicationChildRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: appRoutes.DASHBOARD,
  },
  {
    path: appRoutes.DASHBOARD,
    data: {
      breadcrumb: 'Dashboard',
    },
    loadComponent: () => import('../features/dashboard/dashboard/dashboard.component').then((m) => m.DashboardComponent),
  },
  {
    path: appRoutes.LISTS,
    data: {
      breadcrumb: 'Lists',
    },
    children: [
      { path: '', component: ListsComponent },
      {
        path: ':name/:id',
        children: [
          {
            path: '',
            component: SearchesListComponent,
          },
          {
            path: ':subName/:leadId',
            children: [
              {
                path: '',
                component: SearchDetailsComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: appRoutes.LEADS,
    data: {
      breadcrumb: 'Leads',
    },

    children: [
      {
        path: '',
        loadComponent: () => import('../features/leads/components/leads-section/leads-section.component').then((m) => m.LeadsComponent),
      },
      {
        path: ':name/:leadId',
        loadComponent: () => import('../features/leads/components/lead/lead.component').then((m) => m.LeadComponent),
      },
    ],
  },
  {
    path: appRoutes.SETTINGS,
    data: {
      breadcrumb: 'Settings',
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../features/settings/components/settings-section/settings-section.component').then((m) => m.SettingsSectionComponent),
      },
      {
        path: appRoutes.LINKEDIN_LINK_CALLBACK,
        loadComponent: () =>
          import('../features/settings/components/linkedin-link-callback/linkedin-link-callback.component').then(
            (m) => m.LinkedinLinkCallbackComponent,
          ),
      },
    ],
  },
  {
    path: appRoutes.PROFILE,
    data: {
      breadcrumb: 'Profile',
    },
    loadComponent: () =>
      import('../features/profile/components/profile-section/profile-section.component').then((m) => m.ProfileSectionComponent),
  },
];
