export const appRoutes = {
  AUTH: 'auth',
  LOGIN: 'login',
  REGISTER: 'register',
  LINKEDIN_CALLBACK: 'linkedin-callback',
  LINKEDIN_LINK_CALLBACK: 'linkedin-link-callback',
  FORGOT_PASSWORD: 'forgot-password',
  DASHBOARD: 'dashboard',
  LISTS: 'lists',
  PROFILE: 'profile',
  EMAIL_CONFIRMATION: 'confirm-account',
  SETTINGS: 'settings',
  LOGOUT: 'logout',
  COMPANY_INFO: 'company-info',
  LEADS: 'leads',
};
