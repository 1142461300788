<div class="bg-gray-100 p-[6px] rounded-[16px]">
  <div *ngIf="!isLoading; else loading" class="flex flex-col gap-[16px]">
    <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
      <div class="flex flex-col gap-[16px]">
        <div class="flex items-start gap-[8px] px-[14px] py-[12px]">
          <p-avatar [label]="getInitials(lead.firstName, lead.lastName)" shape="circle" class="[&_.p-avatar]:size-[45px]"></p-avatar>

          <div class="flex flex-col justify-center">
            <h2 class="text-[18px] font-bold text-gray-700 m-0">{{ lead.firstName }} {{ lead.lastName }}</h2>
            <p class="text-gray-600 font-normal text-sm">{{ lead.headline }}</p>
          </div>
        </div>

        <hr class="border-t border-t-gray-100/10 w-full" />

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-[14px]">
          <div class="space-y-1">
            <p class="text-sm font-semibold text-gray-700">Name</p>
            <p class="text-sm text-gray-600">{{ lead.firstName }} {{ lead.lastName }}</p>
          </div>
          <div class="space-y-1">
            <p class="text-sm font-semibold text-gray-700">Position</p>
            <p class="text-sm text-gray-600">{{ lead.position }}</p>
          </div>
          <div class="space-y-1">
            <p class="text-sm font-semibold text-gray-700">Headline</p>
            <p class="text-sm text-gray-600">{{ lead.headline }}</p>
          </div>
        </div>
      </div>
    </p-card>

    <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
      <div class="flex flex-col px-[14px] py-[12px]">
        <h3 class="text-sm text-gray-700 font-semibold m-0">About</h3>
        <p class="text-sm text-gray-600">{{ lead.about }}</p>
      </div>
    </p-card>

    <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
      <div class="flex flex-col px-[14px] py-[12px]">
        <h3 class="text-sm text-gray-700 font-semibold m-0 mb-2">Top Skills</h3>
        <div *ngIf="lead.skills.length > 0; else noSkills">
          <div *ngFor="let skill of lead.skills | slice: 0 : 3" class="text-sm text-gray-600">
            {{ skill.title }}
          </div>
        </div>
        <ng-template #noSkills>
          <p class="text-sm text-gray-700">No top skills available</p>
        </ng-template>
      </div>
    </p-card>

    <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
      <div class="flex flex-col px-[14px] py-[12px]">
        <h3 class="text-sm text-gray-700 font-semibold m-0 mb-2">Recent Posts</h3>
        <div *ngIf="lead.posts.length > 0; else noPosts">
          <div *ngFor="let post of lead.posts" class="border border-gray-200 rounded-lg p-4">
            <p class="text-sm text-gray-700 mb-2">{{ post.content }}</p>
            <div class="flex items-center gap-4 text-sm text-gray-500">
              <span>{{ post.reactionsCount }} reactions</span>
              <span>{{ post.repostsCount }} reposts</span>
            </div>
            <hr class="border-t border-t-gray-100/10 w-full" />
          </div>
        </div>
        <ng-template #noPosts>
          <p class="text-sm text-gray-700">No posts available</p>
        </ng-template>
      </div>
    </p-card>

    <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
      <div class="flex flex-col px-[14px] py-[12px]">
        <h3 class="text-sm text-gray-700 font-semibold m-0 mb-2">Experience</h3>
        <div *ngIf="lead.experiences.length > 0; else noExperience" class="space-y-4">
          <div *ngFor="let experience of lead.experiences" class="text-sm">
            <p class="font-medium text-gray-900">{{ experience.title }}</p>
            <p class="text-gray-700">{{ experience.companyName }}</p>
            <p class="text-gray-500">
              {{ experience.startDate | date: "MMM yyyy" }} -
              {{ experience.endDate ? (experience.endDate | date: "MMM yyyy") : "Present" }}
            </p>
            <p class="text-gray-500">{{ experience.location }}</p>
          </div>
        </div>
        <ng-template #noExperience>
          <p class="text-sm text-gray-500">No experience available</p>
        </ng-template>
      </div>
    </p-card>

    <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
      <div class="flex flex-col px-[14px] py-[12px]">
        <h3 class="text-sm text-gray-700 font-semibold m-0 mb-2">Education</h3>
        <div *ngIf="lead.educations.length > 0; else noEducation" class="space-y-4">
          <div *ngFor="let education of lead.educations" class="text-sm">
            <p class="font-medium text-gray-900">{{ education.school }}</p>
            <p class="text-gray-700">{{ education.qualification }}</p>
            <p class="text-gray-500">
              {{ education.startDate | date: "MMM yyyy" }} -
              {{ education.endDate | date: "MMM yyyy" }}
            </p>
            <p class="text-gray-500">{{ education.description }}</p>
          </div>
        </div>
        <ng-template #noEducation>
          <p class="text-sm text-gray-500">No education details available</p>
        </ng-template>
      </div>
    </p-card>
  </div>

  <ng-template #loading>
    <div class="flex flex-col gap-[16px]">
      <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
        <div class="flex flex-col gap-[16px]">
          <div class="flex items-start gap-[8px] px-[14px] py-[12px]">
            <p-skeleton shape="circle" size="45px"></p-skeleton>
            <div class="flex flex-col justify-center">
              <p-skeleton width="150px" height="24px" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="200px" height="18px"></p-skeleton>
            </div>
          </div>
          <p-skeleton width="100%" height="1px" styleClass="my-2"></p-skeleton>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-[14px]">
            <div class="space-y-1" *ngFor="let i of [1, 2, 3]">
              <p-skeleton width="80px" height="18px"></p-skeleton>
              <p-skeleton width="120px" height="18px"></p-skeleton>
            </div>
          </div>
        </div>
      </p-card>

      <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
        <div class="flex flex-col px-[14px] py-[12px]">
          <p-skeleton width="60px" height="18px" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="100%" height="60px"></p-skeleton>
        </div>
      </p-card>

      <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
        <div class="flex flex-col px-[14px] py-[12px]">
          <p-skeleton width="80px" height="18px" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="120px" height="18px" styleClass="mb-1"></p-skeleton>
          <p-skeleton width="140px" height="18px" styleClass="mb-1"></p-skeleton>
          <p-skeleton width="100px" height="18px"></p-skeleton>
        </div>
      </p-card>

      <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
        <div class="flex flex-col px-[14px] py-[12px]">
          <p-skeleton width="100px" height="18px" styleClass="mb-2"></p-skeleton>
          <div class="border border-gray-200 rounded-lg p-4 mb-2">
            <p-skeleton width="100%" height="40px" styleClass="mb-2"></p-skeleton>
            <div class="flex items-center gap-4">
              <p-skeleton width="80px" height="18px"></p-skeleton>
              <p-skeleton width="80px" height="18px"></p-skeleton>
            </div>
          </div>
          <div class="border border-gray-200 rounded-lg p-4">
            <p-skeleton width="100%" height="40px" styleClass="mb-2"></p-skeleton>
            <div class="flex items-center gap-4">
              <p-skeleton width="80px" height="18px"></p-skeleton>
              <p-skeleton width="80px" height="18px"></p-skeleton>
            </div>
          </div>
        </div>
      </p-card>

      <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
        <div class="flex flex-col px-[14px] py-[12px]">
          <p-skeleton width="90px" height="18px" styleClass="mb-2"></p-skeleton>
          <div class="space-y-4">
            <div *ngFor="let i of [1, 2]">
              <p-skeleton width="150px" height="18px" styleClass="mb-1"></p-skeleton>
              <p-skeleton width="120px" height="18px" styleClass="mb-1"></p-skeleton>
              <p-skeleton width="180px" height="18px" styleClass="mb-1"></p-skeleton>
              <p-skeleton width="100px" height="18px"></p-skeleton>
            </div>
          </div>
        </div>
      </p-card>

      <p-card styleClass="rounded-[12px] [&_.p-card-body]:px-[12px] [&_.p-card-body]:py-[8px] [&_.p-card-content]:p-0 shadow-none">
        <div class="flex flex-col px-[14px] py-[12px]">
          <p-skeleton width="80px" height="18px" styleClass="mb-2"></p-skeleton>
          <div class="space-y-4">
            <div *ngFor="let i of [1, 2]">
              <p-skeleton width="140px" height="18px" styleClass="mb-1"></p-skeleton>
              <p-skeleton width="160px" height="18px" styleClass="mb-1"></p-skeleton>
              <p-skeleton width="180px" height="18px" styleClass="mb-1"></p-skeleton>
              <p-skeleton width="200px" height="18px"></p-skeleton>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </ng-template>
</div>
