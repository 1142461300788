import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../@core/api/api.service';
import { IPaginatedPayload } from '../../../../@core/interfaces/paginated.interface';
import { ILeadDto } from '../interfaces/lead.interface';
import { ILeadSummaryResponseDTO } from '../interfaces/leads-summary.interface';

@Injectable({
  providedIn: 'root',
})
export class LeadController {
  private readonly _path = '/leads';

  constructor(private apiService: ApiService) {}

  getLeadById(id: string): Observable<ILeadDto> {
    return this.apiService.get<ILeadDto>(`${this._path}/${id}`);
  }

  getLeads(payload: IPaginatedPayload): Observable<ILeadSummaryResponseDTO> {
    return this.apiService.get<ILeadSummaryResponseDTO>(this._path, payload);
  }
}
